



























import { PaginatedList } from "@/api/models/common/paginatedList";
import { Changelog, ChangelogDate } from "@/api/models/changelogs/changelog";
import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BFormSelect,
  BDropdownItem,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  BAlert,
  BBadge
} from "bootstrap-vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { ChangelogApplication } from "@/api/models/enums/changelogApplication";
import appSettings from "@/appSettings";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BAlert,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BBadge
  },
})
export default class ListChangelogs extends Vue {
  perPage = 10;
  currentPage = 1;
  perPageOptions = [2, 5, 10, 25, 50, 100];
  isAddNewChangelogSidebarActive = false;
  isUpdateChangelogSidebarActive = false;
  selectedChangelogId = "";
  searchQuery = "";
  timeout: any = null;

  listChangelogs: ChangelogDate[] = [];

  changelogApplication = ChangelogApplication

  async created() {
    await this.loadListChangelogs({
      search: this.searchQuery,
      pageNumber: this.currentPage,
      pageSize: this.perPage,
    });
  }

  get paginationFrom() {
    return (
      this.perPage * (this.currentPage - 1) +
      (this.listChangelogs.length ? 1 : 0)
    );
  }
  get paginationTo() {
    return (
      this.perPage * (this.currentPage - 1) +
      this.listChangelogs.length
    );
  }

  @Watch("currentPage")
  @Watch("perPage")
  async paginationChanged() {
    await this.loadListChangelogs({
      search: this.searchQuery,
      pageNumber: this.currentPage,
      pageSize: this.perPage,
    });
  }

  @Watch('searchQuery')
  async searchChangelogs() {
    if(this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      await this.loadListChangelogs({
        pageNumber: this.currentPage,
        pageSize: this.perPage,
        search: this.searchQuery,
      })
    }, 1000);
  }

  async loadListChangelogs(params: any) {
    if (!params || params?.reset) {
      this.listChangelogs = []
    }
    await this.$http.myressif.changelogs
      .paginatedListApplication(this.changelogApplication.MYRESSIF, appSettings.getTenantId(), params?.search, params?.pageNumber ?? 1, params?.pageSize ?? 10)
      .then((response: ChangelogDate[]) => {
        this.listChangelogs = response;
      });
  }
}
